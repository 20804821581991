<template>
  <div>
    <div id="intro">
      <div class="section">
        <h1>Privacy Policy</h1>
        <div class="minted">Last updated: November&nbsp;9, 2022</div>
      </div>
    </div><!--[ #intro ]-->

    <div id="text-content" class="section">
      <p>This privacy policy (<strong>“Policy”</strong>) describes how BQT Technologies and its related companies (<strong>“Company”</strong>) collect, use and share personal information of&nbsp;consumer users of&nbsp;this website, bqt.io&nbsp;(the <strong>“Site”</strong>). This Policy also applies to&nbsp;any of&nbsp;our other websites that post this Policy. This Policy does not apply to&nbsp;websites that post different statements.</p>
      <h2>WHAT WE&nbsp;COLLECT</h2>
      <p>We&nbsp;get information about you in&nbsp;a&nbsp;range of&nbsp;ways.</p>
      <p><strong>Information You Give Us.</strong> We&nbsp;collect your name, email address as&nbsp;well as&nbsp;other information you directly give&nbsp;us on&nbsp;our Site.</p>
      <p><strong>Information We&nbsp;Get From Others.</strong> We&nbsp;may get information about you from other sources. We&nbsp;may add this to&nbsp;information we&nbsp;get from this Site.</p>
      <p><strong>Information Automatically Collected.</strong> We&nbsp;automatically log information about you and your computer. For example, when visiting our Site, we&nbsp;log your computer operating system type, browser type, browser language, the website you visited before browsing to&nbsp;our Site, pages you viewed, how long you spent on&nbsp;a&nbsp;page, access times and information about your use of&nbsp;and actions on&nbsp;our Site.</p>
      <p><strong>Cookies.</strong> We&nbsp;may log information using “cookies.” Cookies are small data files stored on&nbsp;your hard drive by&nbsp;a&nbsp;website. We&nbsp;may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on&nbsp;your computer until you delete them) to&nbsp;provide you with a&nbsp;more personal and interactive experience on&nbsp;our Site. This type of&nbsp;information is&nbsp;collected to&nbsp;make the Site more useful to&nbsp;you and to&nbsp;tailor the experience with&nbsp;us to&nbsp;meet your special interests and needs.</p>
      <h2>USE OF&nbsp;PERSONAL INFORMATION</h2>
      <p>We&nbsp;use your personal information as&nbsp;follows:</p>
      <ul>
        <li>We&nbsp;use your personal information to&nbsp;operate, maintain, and improve our sites, products, and services.</li>
        <li>We&nbsp;use your personal information to&nbsp;process and deliver contest entries and rewards.</li>
        <li>We&nbsp;use your personal information to&nbsp;respond to&nbsp;comments and questions and provide customer service.</li>
        <li>We&nbsp;use your personal information to&nbsp;send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.</li>
        <li>We&nbsp;use your personal information to&nbsp;communicate about promotions, upcoming events, and other news about products and services offered by&nbsp;us and our selected partners.</li>
        <li>We&nbsp;use your personal information to&nbsp;link or&nbsp;combine user information with other personal information.</li>
        <li>We&nbsp;use your personal information to&nbsp;protect, investigate, and deter against fraudulent, unauthorized, or&nbsp;illegal activity.</li>
        <li>We&nbsp;use your personal information to&nbsp;provide and deliver products and services customers request.</li>
      </ul>
      <h2>SHARING OF&nbsp;PERSONAL INFORMATION</h2>
      <p>We&nbsp;may share personal information as&nbsp;follows:</p>
      <ul>
        <li>We&nbsp;may share personal information with your consent. For example, you may let&nbsp;us share personal information with others for their own marketing uses. Those uses will be&nbsp;subject to&nbsp;their privacy policies.</li>
        <li>We&nbsp;may share personal information when we&nbsp;do&nbsp;a&nbsp;business deal, or&nbsp;negotiate a&nbsp;business deal, involving the sale or&nbsp;transfer of&nbsp;all or&nbsp;a&nbsp;part of&nbsp;our business or&nbsp;assets. These deals can include any merger, financing, acquisition, or&nbsp;bankruptcy transaction or&nbsp;proceeding.</li>
        <li>We&nbsp;may share personal information for legal, protection, and safety purposes.
          <ul>
            <li>We&nbsp;may share information to&nbsp;comply with laws.</li>
            <li>We&nbsp;may share information to&nbsp;respond to&nbsp;lawful requests and legal processes.</li>
            <li>We&nbsp;may share information to&nbsp;protect the rights and property of&nbsp;BQT Technologies, LTD., our agents, customers, and others. This includes enforcing our agreements, policies, and terms of&nbsp;use.</li>
            <li>We&nbsp;may share information in&nbsp;an&nbsp;emergency. This includes protecting the safety of&nbsp;our employees and agents, our customers, or&nbsp;any person.</li>
          </ul>
        </li>
        <li>We&nbsp;may share information with those who need it&nbsp;to&nbsp;do&nbsp;work for&nbsp;us.</li>
      </ul>
      <p>We&nbsp;may also share aggregated and/or anonymized data with others for their own uses.</p>
      <h2>INFORMATION CHOICES AND CHANGES</h2>
      <p>Our marketing emails tell you how to&nbsp;“opt-out.” If&nbsp;you opt out, we&nbsp;may still send you non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with you. You may send requests about personal information to&nbsp;our Contact Information below. You can request to&nbsp;change contact choices, opt-out of&nbsp;our sharing with others, and update your personal information. You can typically remove and reject cookies from our Site with your browser settings. Many browsers are set to&nbsp;accept cookies until you change your settings. If&nbsp;you remove or&nbsp;reject our cookies, it&nbsp;could affect how our Site works for you.</p>
      <h2>CONTACT INFORMATION.</h2>
      <p>We&nbsp;welcome your comments or&nbsp;questions about this privacy policy. You may also contact&nbsp;us at&nbsp;our address:</p>
      <div class="div-p">
        <div>BQT Technologies, LTD.</div>
        <div>Liberty House,</div>
        <div>222 Regent Street,</div>
        <div>London W1B 5TR</div>
        <div>United Kingdom</div>
        <div>Email: support@bqt.io</div>
      </div>
      <h2>CHANGES TO&nbsp;THIS PRIVACY POLICY.</h2>
      <p>We&nbsp;may change this privacy policy at&nbsp;anytime. If&nbsp;we&nbsp;make any changes, we&nbsp;will change the Last Updated date above.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
